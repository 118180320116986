<template>
  <PageLayout title="Search tags" hideBack>
    <Search
    :key="updateSearch"
      @change="setTags($event)"
      :searches="[
        {
          API: feeds,
          paths: ['name', 'email'],
          itemsPath: 'data',
          valuePath: ['name'],
          model: 'tags',
          includeQuery: true,
          category: 'tags',
        },
      ]"
    >
      <div class="pb-2 px-4" v-if="suggestions?.filter(s => !tags.includes(s)).length > 0">
        <div class="text-gray-600 text-xs">
          Suggested: 
          <p class="inline-block" v-for="(suggestion, i) in suggestions.filter(s => !tags.includes(s))" :key="i">
            <span class="underline pr-2 font-medium font-poppins" @click="selectTag(suggestion)">{{ suggestion }}</span> 
          </p> 
        </div>
      </div>
      <div class="py-2 flex flex-wrap" :key="this.$store.state.postPopup.update">
        <FilterTag v-for="(tag, i) in tags" :key="i" active :removable="tag != this.$store.state.user?.university?.abbrev" :class="{ 'uppercase' : tag == this.$store.state.user?.university?.abbrev}" class="m-1" @remove="removeTag(tag)">{{
          tag
        }}</FilterTag>
      </div>
      <div class="flex flex-col space-y-1 divide-y" :key="update + this.$store.state.postPopup.update">
        <p
          v-for="(tag, i) in results"
          :key="i"
          class="py-2 w-full font-poppins"
          @click="selectTag(tag)"
        >
          {{ tag }}
        </p>
      </div>
    </Search>
    <div class="px-4 fixed bottom-24 w-full">
      <Button shape="full" icon="chevron-left" iconPosition="left" @click="backToPosting">Back</Button>
    </div>
  </PageLayout>
</template>

<script>
import Search from "../../components/Feed/Search.vue";
import FilterTag from "../../components/Feed/FilterTag.vue";

import FeedsAPI from "../../../FeedsAPI";
import PageLayout from "../../components/base/pageLayout.vue";
import Button from "../../components/Feed/Button.vue";

export default {
  name: "Tags Search",
  components: {
    Search,
    FilterTag,
    PageLayout,
    Button
},
  data() {
    return {
      tags: this.$store.state.postPopup.tags,
      results: [],
      update: 0,
      feeds: FeedsAPI,
      updateSearch: 0,
      suggestions: this.$store.state.user.classes.map(_class => _class.desc)
    };
  },
  methods: {
    setTags(res) {
      this.results = res.tags?.filter((tag) => !this.tags.includes(tag)) || [];
      this.suggestions = this.suggestions.filter(tag => !this.tags.includes(tag))
      this.update++;
    },
    selectTag(tag) {
      this.$store.commit('postPopup/addTag', tag);
      this.updateSearch++
      this.setTags(this.results);
    },
    removeTag(tag) {
      this.$store.commit('postPopup/removeTag', tag);
      this.setTags(this.results);
    },
    backToPosting() {
      this.$store.commit('postPopup/activate')
      this.$router.go(-1)
    },
  },
};
</script>